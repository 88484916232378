import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Landing from './components/Landing';
import Login from './components/Login';
import TweetGenerator from './components/TweetGenerator';
import ProtectedRoute from './components/ProtectedRoute';
import Subscription from './components/Subscription';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import ContactUs from './components/ContactUs';
import './styles/App.css';
import { AuthProvider } from './context/AuthContext';
import ReactGA from 'react-ga4';

const paypalOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "USD",
  intent: "capture"
};

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    ReactGA.send({ hitType: "page_view", page_path: location.pathname });
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "page_view", page_path: location.pathname });
  }, [location]);

  return (
    <AuthProvider>
      <PayPalScriptProvider options={paypalOptions}>
        <div className="App">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route 
              path="/generate" 
              element={
                <ProtectedRoute>
                  <TweetGenerator />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </div>
      </PayPalScriptProvider>
    </AuthProvider>
  );
}

export default App;